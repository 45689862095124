<template>
    <div v-if="!shareTag">
    <van-tabs v-model.active="activeTab" @change="handleTabsChange"   :border="false" swipeable background="#fff" title-active-color="rgb(255, 110, 1)">
        <van-tab title="视频详情">
            <div  :class="shareTag?['container_box','shared-height']:'container_box'"  id="video_box">
                <div  :class="shareTag?['van_swipe','shared-height']:'van_swipe'">
<!--                    vant van-swipe 滑动组件 -->
                    <van-swipe
                            :show-indicators="false"
                            @change="onChange"
                            vertical
                            :loop="false"
                            :initial-swipe="initialSwipe"
                    >
                        <van-swipe-item
                                v-for="(item, index) in videoList"
                                :key="index"
                                :class="shareTag?['product_swiper','shared-height']:'product_swiper'"
                        >
                            <div  :class="shareTag?['video_container','shared-height']:'video_container'">
                                <video
                                        :class="shareTag?['video_box','shared-height']:'video_box'"
                                        loop
                                        preload="auto"
                                        playsinline
                                        webkit-playsinline="true"
                                        x5-video-player-type="h5-page"
                                        x5-video-player-fullscreen="true"
                                        x5-playsinline
                                        x-webkit-airplay="allow"
                                        x5-video-orientation="portrait"
                                        :poster="
                item.videoCoverUrl
                  ? fileHost + item.videoCoverUrl
                  : '/img/jpg/videoDetailDefaultCover.jpg'
              "
                                        :src="fileHost + item.videoUrl"
                                        :playOrPause="playOrPause"
                                        @click="playOrPauseVideo"
                                        @ended="onPlayerEnded($event)"
                                        @timeupdate="ontimeupdate($event)"
                                ></video>
                                <!-- 封面 -->
                                <img
                                        v-show="isVideoCoverShow"
                                        class="play"
                                        @click="playvideo"
                                        :src="
                item.videoCoverUrl
                  ? fileHost + item.videoCoverUrl
                  : '/img/jpg/videoDetailDefaultCover.jpg'
              "
                                        @load="videoCoverImgLoad"
                                />
                                <!-- 播放暂停按钮 -->
                                <img
                                        v-show="iconPlayShow"
                                        class="icon_play"
                                        @click="playvideo"
                                        :src="require('../../assets/play.png')"
                                />
                            </div>
                            <!-- 右侧头像、点赞、评论、分享功能 -->
                            <div class="tools_right">
                                <div class="tools_r_li">
                                    <img
                                            :src="headImageUrl"
                                            class="tag_image"
                                            @click="navigateToUserCard"
                                    />
                                    <!--              <i-->
                                    <!--                class="iconfont icon-jiahao tag_add"-->
                                    <!--                v-show="!item.tagFollow"-->
                                    <!--                @click="checkSubscribe(item, index)"-->
                                    <!--              ></i>-->
                                    <!--              <i-->
                                    <!--                class="iconfont icon-duihao tag_dui"-->
                                    <!--                v-show="item.tagFollow"-->
                                    <!--                :class="item.tagFollow ? 'tag_dui_active' : ''"-->
                                    <!--              ></i>-->
                                </div>
                                <!--            <div class="tools_r_li" @click="changeFabulous(item, index)">-->
                                <!--              <i-->
                                <!--                class="iconfont icon-shoucang icon_right"-->
                                <!--                :class="item.fabulous ? 'fabulous_active' : ''"-->
                                <!--              ></i>-->
                                <!--              <i-->
                                <!--                      class="iconfont icon-shoucang icon_right"-->
                                <!--              ></i>-->
                                <!--              <div class="tools_r_num">52.1w</div>-->
                                <!--            </div>-->
                                <div class="tools_r_li">
                                    <!--            <icon-svg iconClass="iconicon_huabanfuben" />-->
                                    <van-icon
                                            name="like"
                                            v-bind:color="item.fabulous ? '#E72F28' : '#d0d2d0'"
                                            size="0.96rem"
                                            @click="changeFabulous(item, index)"
                                    />
                                    <div class="tools_r_num">{{ totalLikesText }}</div>
                                </div>
                                <div class="tools_r_li" v-if="!shareTag">
                                    <!--             <icon-svg iconClass="iconzhuanfa" />-->
                                    <van-icon
                                            name="share"
                                            color="#d0d2d0"
                                            size="0.96rem"
                                            @click="loadShow(vdId)"
                                    />
                                    <div class="tools_r_num">{{forwardingNumberText}}</div>
                                </div>
                              <div class="tools_r_li" style="color: rgb(208, 210, 208);"  @click="downloadVideo(item, index)">
                                <!--            <icon-svg iconClass="iconicon_huabanfuben" />-->
                                <van-icon
                                    name="down"
                                    size="0.96rem"
                                />
                                <div class="tools_r_num">保存</div>
                              </div>
                            </div>
                            <!-- 底部作品描述 -->
                            <div class="production_box">
                                <div class="production_name">@{{ originalSharerName }}</div>
                                <div class="production_des">
                                    {{ item.name }}
                                </div>
                            </div>
                            <!-- 视频进度条 -->
                            <div class="progressBar"  @click="onProgressClick($event)" v-show="showVideoProgress">
                                <div class="timeBar"></div>
                                <div class="progressIcon" @touchmove="onTouchmove($event)">
                                    <div class="progressIconEntity"></div>
                                </div>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <!--    左上角导航箭头-->
                <div
                        class="nav-bar-left-not-shared"
                        @click="comback"
                        v-if="!shareTag && !hideTitleBar"
                >
                    <van-icon name="arrow-left" color="#FFF" size="0.64rem"/>
                </div>
                <!--右上角编辑按钮-->
                <div
                        class="nav-bar-right-not-shared"
                        @click="editVideo(vdId)"
                        v-if="!shareTag"
                >
                    <icon-svg icon-class="iconedit-3" style="color:#FFF;font-size:0.64rem"></icon-svg>
                </div>
                <!-- 弹出分享框 -->

            </div>

        </van-tab>
        <van-tab title="传播轨迹" >
            <div class="related-clues">
                <p v-if="withClueList.length">关联线索</p>
                <van-list v-if="withClueList.length">
                    <van-cell v-for="it in withClueList" :key="it">
                        <div class="clues-box">
                            <div class="img-name">
                                <img
                                        :src="it.headImgUrl ? it.headImgUrl : morenHeadImg"
                                        alt=""
                                />
                                <div class="creater-info">
                                    <p>{{ it.name }} {{
                                        it.phone
                                        ? `${it.phone.slice(0, 3)}****${it.phone.slice(7)}`
                                        : ""
                                        }}</p>
                                </div>
                            </div>
                            <div class="clues-details" @click="loadCuleSDetail(it.clueId)">
                                <p>
                                    浏览时间：{{
                                    it.browseTime
                                    ? `${it.browseTime.slice(0, 10)} ${it.browseTime.slice(
                                    11,
                                    19
                                    )}`
                                    : ""
                                    }}
                                </p>
                                <p>浏览时长：{{ it.browseSecond }}秒</p>
                                <p>浏览位置：{{it.clueShareSource}}</p>
                                <p>分享人：{{ it.createName }}</p>
                                <p>推荐人：{{it.recommend_Name}}</p>
                            </div>
                        </div>
                    </van-cell>
                </van-list>
                <div v-else class="no-clues">
                  <img :src="nodataImg">
                </div>
            </div>
        </van-tab>
    </van-tabs>
    </div>
    <div v-else :class="['container_box','shared-height']"  id="video_box">
            <div   :class="['van_swipe','shared-height']">
                <!--vant van-swipe 滑动组件 -->
                <van-swipe
                        :show-indicators="false"
                        @change="onChange"
                        vertical
                        :loop="false"
                        :initial-swipe="initialSwipe"
                >
                    <van-swipe-item
                            v-for="(item, index) in videoList"
                            :key="index"
                            :class="['product_swiper','shared-height']"
                    >
                        <div   :class="['video_container','shared-height']">
                            <video
                                    class="video_box"
                                    loop
                                    preload="auto"
                                    playsinline
                                    webkit-playsinline="true"
                                    x5-video-player-type="h5-page"
                                    x5-video-player-fullscreen="true"
                                    x5-playsinline
                                    x-webkit-airplay="allow"
                                    x5-video-orientation="portrait"
                                    :poster="
                item.videoCoverUrl
                  ? fileHost + item.videoCoverUrl
                  : '/img/jpg/videoDetailDefaultCover.jpg'
              "
                                    :src="fileHost + item.videoUrl"
                                    :playOrPause="playOrPause"
                                    @click="playOrPauseVideo"
                                    @ended="onPlayerEnded($event)"
                                    @timeupdate="ontimeupdate($event)"
                            ></video>
                            <!-- 封面 -->
                            <img
                                    v-show="isVideoCoverShow"
                                    class="play"
                                    @click="playvideo"
                                    :src="
                item.videoCoverUrl
                  ? fileHost + item.videoCoverUrl
                  : '/img/jpg/videoDetailDefaultCover.jpg'
              "
                                    @load="videoCoverImgLoad"
                            />
                            <!-- 播放暂停按钮 -->
                            <img
                                    v-show="iconPlayShow"
                                    class="icon_play"
                                    @click="playvideo"
                                    :src="require('../../assets/play.png')"
                            />
                        </div>
                        <!-- 右侧头像、点赞、评论、分享功能 -->
                        <div class="tools_right">
                            <div class="tools_r_li">
                                <img
                                        :src="headImageUrl"
                                        class="tag_image"
                                        @click="navigateToUserCard"
                                />
                                <!--              <i-->
                                <!--                class="iconfont icon-jiahao tag_add"-->
                                <!--                v-show="!item.tagFollow"-->
                                <!--                @click="checkSubscribe(item, index)"-->
                                <!--              ></i>-->
                                <!--              <i-->
                                <!--                class="iconfont icon-duihao tag_dui"-->
                                <!--                v-show="item.tagFollow"-->
                                <!--                :class="item.tagFollow ? 'tag_dui_active' : ''"-->
                                <!--              ></i>-->
                            </div>
                            <!--            <div class="tools_r_li" @click="changeFabulous(item, index)">-->
                            <!--              <i-->
                            <!--                class="iconfont icon-shoucang icon_right"-->
                            <!--                :class="item.fabulous ? 'fabulous_active' : ''"-->
                            <!--              ></i>-->
                            <!--              <i-->
                            <!--                      class="iconfont icon-shoucang icon_right"-->
                            <!--              ></i>-->
                            <!--              <div class="tools_r_num">52.1w</div>-->
                            <!--            </div>-->
                            <div class="tools_r_li">
                                <!--            <icon-svg iconClass="iconicon_huabanfuben" />-->
                                <van-icon
                                        name="like"
                                        v-bind:color="item.fabulous ? '#E72F28' : '#d0d2d0'"
                                        size="0.96rem"
                                        @click="changeFabulous(item, index)"
                                />
                                <div class="tools_r_num">{{ totalLikesText }}</div>
                            </div>
                            <div class="tools_r_li" v-if="!shareTag">
                                <!--             <icon-svg iconClass="iconzhuanfa" />-->
                                <van-icon
                                        name="share"
                                        color="#d0d2d0"
                                        size="0.96rem"
                                        @click="loadShow(vdId)"
                                />
                                <div class="tools_r_num">{{ totalLikesText }}</div>
                            </div>
                        </div>
                        <!-- 底部作品描述 -->
                        <div class="production_box">
                            <div class="production_name">@{{ originalSharerName }}</div>
                            <div class="production_des">
                                {{ item.name }}
                            </div>
                        </div>
                        <!-- 视频进度条 -->
                        <div class="progressBar"  @click="onProgressClick($event)" v-show="showVideoProgress">
                            <div class="timeBar"></div>
                            <div class="progressIcon" @touchmove="onTouchmove($event)">
                                <div class="progressIconEntity"></div>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <!--    左上角导航箭头-->
            <div
                    class="nav-bar-left"
                    @click="comback"
                    v-if="!shareTag && !hideTitleBar"
            >
                <van-icon name="arrow-left" color="#FFF" size="0.64rem"/>
            </div>
            <!--右上角编辑按钮-->
            <div
                    class="nav-bar-right"
                    @click="editVideo(vdId)"
                    v-if="!shareTag"
            >
                <icon-svg icon-class="iconedit-3" style="color:#FFF;font-size:0.64rem"></icon-svg>
            </div>
            <!-- 弹出分享框 -->
            <div class="share_box">
                <van-share-sheet
                        v-model:show="showShare"
                        title="分享"
                        :options="options"
                        @select="onSelectShare"
                        cancel-text="取消"
                        safe-area-inset-bottom
                >
                </van-share-sheet>
            </div>
        </div>
    <van-share-sheet
            v-model:show="showShare"
            title="分享"
            :options="shareOptions"
            @select="onSelectShare"
            cancel-text="取消"
            safe-area-inset-bottom
    >
    </van-share-sheet>
    <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
        <div class="poster-details" v-if="showImg" ref="imageWrapper">
            <img :src="posterPic" crossOrigin="anonymous"/>
            <div class="text-qrcode">
                <div class="user-info-tag">
                    <div class="user-info">
                        <img :src="headImgUrl">
                        <div class="left-text">
                            <p>{{ userForm.name }}</p>
                            <p>{{ userForm.merchantName }}</p>
                        </div>
                    </div>
                    <div class="user-tag">
                        <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
                    </div>
                </div>
                <div class="qrCodeUrl-all">
                    <div class="qrCodeUrl-cls">
                        <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
                    </div>
                    <p>立即扫码</p>
                    <p>了解更多内容</p>
                </div>
            </div>
        </div>
        <div v-else class="posterImg-hb">
            <img class="card-img" :src="imgSrc" ref="cardImgRef" />
        </div>
    </div>
</template>
<script>
import {computed, nextTick, onMounted, onUnmounted, reactive, ref, toRefs, watch} from "vue";
    import {useRoute, useRouter} from "vue-router";
    import {useVideosPlay} from "@/hooks/videoPlay.js";
    import {flutterShowToast} from "@/utils/appChannel";
    import {useWxAuthorization} from "@/hooks/wxAuthorization";
    import {addClue, addClueBehaviorInfo, } from "../../api/user/clue";
    import {getVideoClueInfoList} from "@/api/video";
// import iconSvg from "@/components/IconSvg"
    import $ from "jquery";
    import {
        cancelThumbsUp,
        doThumbsUp,
        getVideoDetail,
        getVideoDetailByPage,
        recordUserVideoShareInfo,
        updateViewsNumber,
        getVideoDetailsUrl
    } from "../../api/video";
    import {Toast} from "vant";
    import {initWechatShare} from "../../hooks/initWechatShare";
    import IconSvg from "../../components/IconSvg";
    // import {ref} from 'vue';
    import { createPosterAppShare,getQrCodeUrl } from "@/api/common";
    import {posterShare} from "@/utils/posterShare"
import html2canvas from "html2canvas";
import {findPosterImg} from "../../api/file";
import { loadImages } from "@/utils/commonUtil";
import {getUserCardByPoster} from "@/api/user/userCard"

    export default {
        components: {
            IconSvg
            // iconSvg
        },
        setup() {
          const imageWrapper = ref(null);
          const cardImgRef = ref(null);
          const posterState = reactive({
            userForm:{},
            userTags:[],
            headImgUrl:'',
            nodataImg:require("../../assets/images/no-data.png"),
            showSharePoster:false,
            shareJumpUrl:'',
            posterId:'',
            imgSrc:'',
            qrCodeUrl:'',
            posterName:'',
            posterPic:'',
            sharePosterImg:'',
            showOverlay: false,
            showImg: true,
            shareOptions:[],
            defaultImg: require("@/assets/images/videoListDefaultCover.png"),
            imgUrl: process.env.VUE_APP_WATCH_FILE_API,
            fileHost: process.env.VUE_APP_WATCH_FILE_API + "/",
            options: [
              { name: "微信", icon: require("@/assets/icons/weChat2.png") },
              { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
              { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
              { name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
            ],
            posterOptions: [
              {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
              {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
            ],
          })
            const {
                state,
                onChange,
                playvideo,
                playOrPauseVideo,
                changeProcess,
                ontimeupdate,
                onTouchmove,
                onProgressClick
            } = useVideosPlay();
            let internal = null;
            let seconds = 0;
            const router = useRouter();
            const route = useRoute();
            const vdId = route.params.vdId;
            const vusrId = route.params.vusrId;
            const merchantId = route.params.merchantId;
            const recommendClueId = route.params.recommendClueId?route.params.recommendClueId:''
            state.hideTitleBar = route.query && route.query.hideTitleBar ? true : false;
            state.vdId = vdId;
            if(route.query && route.query.from) {
                state.from = route.query.from
            }
          watch(() => state.showShare, (newVal, oldVal) => {
            console.log('新值：',newVal,'老值',oldVal)
            if (state.showShare===false){
              posterState.showOverlay = false
              posterState.showImg = true
            }
          })
            //如果是分享的视频,
            if (vusrId) {
                console.log("===视频是分享出来的===");
                state.cardUrl = "/userCard";
                state.shareTag = true;
                //更新浏览次数
                updateViewsNumber({vdId: state.vdId});
            } else {
                state.cardUrl = "/wxShareUserCard";
                state.shareTag = false;
            }
            const downloadVideo = (item)=>{
              console.log("下载的视频==========",posterState.fileHost+item.videoUrl)
              // flutterShowToast()
              Toast({
                message:'正在下载...',
                duration:500,
              });
              let params = {
                shareType:"saveFile",
                desc:"",
                // miniProgramType:'saveFile',
                urlEntity:{
                  content:posterState.fileHost+item.videoUrl
                }
              }
              flutterShowToast(params)
              // downloads(posterState.fileHost+item.videoUrl)
            }
          // const downloads=(url)=>{
          //   // let url //下载地址
          //   let xhr = new XMLHttpRequest();
          //   xhr.open('get', url, true); // 也可以使用POST方式，根据接口
          //   // 						xhr.setRequestHeader("dis_k",`cb9a62d3796e276f8707318b3c48ed3d`);
          //   // xhr.setRequestHeader("dis_t",`1617786336`);
          //   xhr.responseType = "blob"; // 返回类型blob
          //
          //   xhr.onload = function () {
          //     if (this.status === 200) {
          //       let blob = this.response;
          //       let reader = new FileReader();
          //       reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
          //       reader.onload = function (e) {
          //         let a = document.createElement('a');
          //         a.download ='躺赢视频'; //下载文件名
          //         a.href = e.target.result;
          //         a.click();
          //         window.URL.revokeObjectURL(e.target.result)
          //       };
          //     }
          //   };
          //   xhr.send();
          // }

          //获取视频详情
            const getInitialData = async () => {
                let data = await getVideoDetail({vdId: vdId, openId: state.openId})
                state.videoItem = Object.assign(state.videoItem,data);
              posterState.posterName = data.name
              // posterState.posterPic = data.videoCoverUrl?posterState.imgUrl+"/"+data.videoCoverUrl:posterState.defaultImg
              // posterState.sharePosterImg = data.videoCoverUrl?posterState.imgUrl+"/"+data.smallCoverPic:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
                let videoList = [data];
                //设置初始分享人头像和名称
                state.originalSharerName = data.originalSharerName;
                state.headImageUrl = data.headImageUrl
                    ? process.env.VUE_APP_WATCH_FILE_API + "/" + data.headImageUrl
                    : require("../../assets/head-img-not-found.png");
                state.belongMerchantId = data.belongMerchantId;
                //再获取下一条视频
                let next = await getVideoDetailByPage({
                    current: state.page,
                    belongMerchantId: state.belongMerchantId,
                    openId: state.openId,
                })
                let records = next.records;
                for (let index in records) {
                    if (records[index].vdId == state.vdId) {
                        records.splice(index, 1);
                    }
                }
                videoList.push(...records);
                state.videoList = videoList;
              const p = {
                busiId:state.vdId,
                busiCat:'poster',
                groupId:'sharePosterPic',
              }
              findPosterImg(p).then((res)=>{
                if (res.length>0){
                  //获取小图
                  findPosterImg({
                    busiId:state.vdId,
                    busiCat:'poster',
                    groupId:'sharePosterPic:S',
                  }).then((sImg)=>{
                    posterState.sharePosterImg = sImg[0].url?posterState.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
                    console.log("分享小图",posterState.sharePosterImg)
                    // sImg.map(it=>{
                    //   it.url = posterState.imgUrl+"/"+it.url
                    //   console.log('全部小图',it.url)
                    // })
                    console.log("分享小图",sImg)
                  })
                  posterState.posterPic = res[0].url?posterState.imgUrl+"/"+ res[0].url:posterState.defaultImg
                }else {
                  posterState.posterPic = posterState.defaultImg
                  posterState.sharePosterImg = "https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
                }
              })
            };
            if (!state.shareTag) {
                getInitialData();
            }
            const useWxAuth = async () => {
                let data = await useWxAuthorization()
                if (data) {
                    state.openId = data.openId;
                } else {
                    state.openId = route.query.openId;
                }

                if (state.openId) {
                    await getInitialData();
                    await handleFirstAddClue();
                    countUserAction({clueId: state.clueId, openId: state.openId, type: 1});
                    let temp = location.href.split("?")[0];
                    if(recommendClueId){
                        temp =temp.replace(recommendClueId,state.clueId)
                    }else {
                        temp = `${temp}/${state.clueId}`
                    }
                    let shareImg;
                    if (state.videoItem.smallCoverPic) {
                        shareImg = `${process.env.VUE_APP_WATCH_FILE_API}/${state.videoItem.smallCoverPic}`;
                    } else {
                        shareImg = "https://wca.ujiaku.com/upload/wx/share-default.png";
                    }
                    // eslint-disable-next-line no-undef
                    wx.miniProgram.getEnv(function (res) {
                        if (res.miniprogram) {
                            // eslint-disable-next-line no-undef
                            wx.miniProgram.postMessage({
                                data: {
                                    title: state.videoItem.name,
                                    img: shareImg,
                                    openId: state.openId,
                                    clueId: state.clueId,
                                    type: 4,
                                    shareUrl:temp,
                                },
                            });
                        } else {
                            let title = state.videoItem.name;
                            let desc = state.videoItem.name;
                            let appPhone;
                            if (localStorage.getItem("appPhone")) {
                                appPhone = localStorage.getItem("appPhone")
                            } else {
                                appPhone = route.query.appPhone
                            }
                            let url = `${
                                process.env.VUE_APP_WECHAT_SHARE
                            }/shareForward?shareUrl=${temp}?appPhone=${appPhone}`;
                            let imgUrl = shareImg;
                            initWechatShare({
                                title,
                                desc,
                                url,
                                imgUrl,
                                openId: state.openId,
                                clueId: state.clueId,
                                type: 4
                            });
                        }
                    });
                }
            }
            //如果是分享的视频，走授权流程
            if (state.shareTag) {
                useWxAuth()
                // useWxAuthorization().then((data) => {
                //     if (data) {
                //         state.openId = data.openId;
                //     } else {
                //         state.openId = route.query.openId;
                //     }
                //     if (state.openId) {
                //         getInitialData();
                //         handleFirstAddClue().then(() => {
                //             countUserAction({clueId: state.clueId, openId: state.openId, type: 1});
                //         });
                //     }
                // });
            }
            //关注该作者
            // const checkSubscribe = (item) => {
            //   state.videoList.map((v) => {
            //     v.author_id == item.author_id ? (v.tagFollow = true) : "";
            //   });
            // };

            //tab改变
            const handleTabsChange =async ()=>{
                //组件卸载时假设视频正在播放,需要停止视频
                state.playOrPause = true;
                playOrPauseVideo();
                Toast.loading({
                    forbidClick: true,
                    message: "加载中",
                    overlay:true
                });
                try {
                    let vdId = state.videoList[state.current].vdId
                    let data =await getVideoClueInfoList({vdId})
                    state.withClueList = data.clueList
                    console.log(state.withClueList)
                }catch (e) {
                    console.log(e)
                }finally {
                    Toast.clear()
                }


            }
            //查看线索详情
            const loadCuleSDetail = (id) => {
                let params = {
                    shareType: "openCustomPage",
                    pageName: "clue_detail_page",
                    param:{ clueId: id},
                };
                flutterShowToast(params);
            };
            //改变喜欢状态
            const changeFabulous = (item, index) => {
                state.videoList[index].fabulous = !state.videoList[index].fabulous;
                if (!state.shareTag) return false;
                let vdId = state.videoList[index].vdId;
                let openId = state.openId;
                if (state.videoList[index].fabulous) {
                    state.videoList[index].totalLikes =
                        state.videoList[index].totalLikes + 1;
                    doThumbsUp({
                        vdId: vdId,
                        openId: openId,
                    }).then(() => {
                        countUserAction({clueId: state.clueId, openId: state.openId, type: 6});
                    }).catch(() => {
                        state.videoList[index].fabulous = false;
                        state.videoList[index].totalLikes =
                            state.videoList[index].totalLikes - 1;
                    });
                } else {
                    state.videoList[index].totalLikes =
                        state.videoList[index].totalLikes - 1;
                    cancelThumbsUp({
                        vdId: vdId,
                        openId: openId,
                    }).catch(() => {
                        state.videoList[index].fabulous = true;
                        state.videoList[index].totalLikes =
                            state.videoList[index].totalLikes + 1;
                    });
                }
            };
            const onPlayerEnded = () => {
                //视频结束
                state.isVideoCoverShow = true;
                state.current += state.current;
            };
            //绑定回退事件
            const comback = () => {
              if(!route.query) {
                router.back()
                return
              }
              if(route.query.addPoster=='true'){
                router.push({
                  path:'/videoVisitors',
                  name:'video',
                  params:{
                    addPoster:true
                  }
                })
                return
              }
              if(route.query.fromPoster=='true'){
                router.push({
                  path:'/videoVisitors',
                  name:'video',
                  params:{
                    fromPoster:true
                  }
                })
                return

              }
              if(route.query.from == 'appHome') {
                let param = {
                  shareType : "backIndexPage"
                }
                flutterShowToast(param)
                return
              }
                if (state.from == "home") {
                    console.log("===from===",state.from)
                    router.replace("/")
                } else {
                    router.back()
                }
            };
            //绑定编辑视频事件
            const editVideo = () => {
                let id = state.videoList[state.current].vdId
                // router.push(`/video/editVideo/${id}`)

              router.replace({
                path: `/video/editVideo/${id}`
              });
            };
            //分享视频
            const loadShow = (id) => {
                state.showShare = true;
                console.log("视频id", id);
              console.log("视频id", id);
              posterState.shareVdId = id;

              getUserCardByPoster().then(res=>{
                if (res){
                  if (res.headImgUrl){
                    posterState.headImgUrl = posterState.imgUrl+'/'+res.headImgUrl
                  }else {
                    posterState.headImgUrl = require("@/assets/poster_headerImg_default.png")
                  }
                  posterState.userForm = res
                  posterState.userTags = res.impressionLabel.split(',')
                  if (posterState.userTags.length>4){
                    posterState.userTags = posterState.userTags.slice(0,4)
                  }
                }
              })
              let params = {
                sourceId:id,//分享的id
                sourceType:3,//来源类别 1-文章 2-案例 3-视频 4-工地
              }
              getQrCodeUrl(params).then((res)=>{
                posterState.shareJumpUrl = res.jumpUrl
                if(res.qrCodeUrl){
                  posterState.qrCodeUrl = res.qrCodeUrl
                }
                posterState.showSharePoster = true
              })
              posterState.shareOptions = posterState.options
            };
          const loadPoster = ()=>{
            savePoster()
            setTimeout(()=>{
              Toast.clear()
            })
          }
            //选择分享菜单
            const onSelectShare = (option) => {
                let shareType;
                let content;
                let merchantId = localStorage.getItem('merchantId')
                let currentVideo = state.videoList[state.current]
                let vdId = currentVideo.vdId
                if (option.name === "微信") {
                    shareType = "shareToMiniProgram";
                    content = `${process.env.VUE_APP_WECHAT_SHARE}/video/videoDetail/${vdId}/123/1/${merchantId}`;
                  let param = {
                    miniProgramType:currentVideo.jumpApplets,
                    shareType: shareType,
                    urlEntity: {
                      title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
                      content: content,
                    },
                    title: currentVideo.name,
                    shareImg: currentVideo.smallCoverPic
                        ? `${process.env.VUE_APP_WATCH_FILE_API}/${currentVideo.smallCoverPic}`
                        : null,
                  };
                  console.log(param);
                  flutterShowToast(param);
                  state.showShare = false;
                  recordUserVideoShareInfo({vdId: vdId});
                } else if (option.name === "朋友圈") {
                    shareType = "shareH5PageToTimeLine";
                    content = `${process.env.VUE_APP_WECHAT_SHARE}/video/videoDetail/${vdId}/123/2/${merchantId}`;
                  let param = {
                    miniProgramType:currentVideo.jumpApplets,
                    shareType: shareType,
                    urlEntity: {
                      title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
                      content: content,
                    },
                    title: currentVideo.name,
                    shareImg: currentVideo.smallCoverPic
                        ? `${process.env.VUE_APP_WATCH_FILE_API}/${currentVideo.smallCoverPic}`
                        : null,
                  };
                  console.log(param);
                  flutterShowToast(param);
                  state.showShare = false;
                  recordUserVideoShareInfo({vdId: vdId});
                }  else if(option.name === "复制链接") {
                    getVideoDetailsUrl(vdId).then((res) => {
                        let param = {
                            shareType: "copyText",
                            desc: res
                        };
                        flutterShowToast(param)
                        Toast.success("复制成功")
                        state.showShare = false
                    })
                    return
                }else if(option.name === "分享海报"){
                  if(posterState.showSharePoster===true){
                    Toast.loading({
                      message: '生成海报中...',
                      forbidClick: true,
                      duration:0,
                      className:'posterToast',
                    });
                    posterState.showOverlay = true;
                    posterState.shareOptions = []
                    posterState.shareOptions = posterState.posterOptions
                    // state.showImg = true
                    let arr = []
                    arr.push(posterState.posterPic)
                    arr.push(posterState.qrCodeUrl)
                    arr.push(posterState.headImgUrl)
                    loadImages(arr,loadPoster)
                    // setTimeout(()=>{
                    //   savePoster()
                    // },200)
                  }
                  // return;
                }else if(option.name === "微信分享"||option.name === "朋友圈分享"){
                  if(posterState.showSharePoster===true){
                    Toast.loading({
                      message: '正在\n打开微信...',
                      forbidClick: true,
                      duration:3000,
                      className:'posterToast',
                    });
                    let p = {
                      sourceId: posterState.shareVdId,//分享的id
                      sourceType:3,//来源类别 1-文章 2-案例 3-视频 4-工地
                      jumpUrl:posterState.shareJumpUrl
                    }
                    createPosterAppShare(p).then((res)=>{
                      console.log("创建二海报",res)
                    })
                    let param = {
                      imgSrc:posterState.imgSrc,
                      id:posterState.shareVdId,
                      bigPosterImgUrl:posterState.sharePosterImg,
                    }
                    setTimeout(()=>{
                      posterShare(option,param)
                    })
                  }
                  // return;
                }
                // let param = {
                //     miniProgramType:currentVideo.jumpApplets,
                //     shareType: shareType,
                //     urlEntity: {
                //         title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
                //         content: content,
                //     },
                //     title: currentVideo.name,
                //     shareImg: currentVideo.smallCoverPic
                //         ? `${process.env.VUE_APP_WATCH_FILE_API}/${currentVideo.smallCoverPic}`
                //         : null,
                // };
                // console.log(param);
                // flutterShowToast(param);
                // state.showShare = false;
                // recordUserVideoShareInfo({vdId: vdId});
            };
            //跳转到名片
            const navigateToUserCard = () => {
                if (state.shareTag) {
                    let miniProgramPhone = route.query.state
                        ? route.query.state
                        : route.query.miniProgramPhone;
                    router.push({
                        path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
                        query: {miniProgramPhone: miniProgramPhone, openId: state.openId},
                    });
                } else {
                    router.push(`/userCard`);
                }
            };
            const handleAddClue = () => {
                if (state.shareTag) {
                    if (seconds > 0) {
                        let miniProgramPhone = route.query.state
                            ? route.query.state
                            : route.query.miniProgramPhone;
                        let param = {
                            clueId: state.clueId,
                            browseSecond: seconds,
                            shareId: state.videoItem.vdId,
                            clueSource: 2,
                            beSharePhone: miniProgramPhone,
                            openId: state.openId,
                            title: state.videoItem.name,
                            createId: state.videoItem.loginUserId,
                            clueShareSource: route.params.clueShareSource,
                            recommend_Clue_Id:recommendClueId,
                            merchantId
                        };
                        $.ajax({
                            async: false,
                            url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                            type: "post",
                            contentType: "application/json;charset=UTF-8",
                            data: JSON.stringify(param),
                        });
                    }
                    seconds = 0;
                    clearInterval(internal);
                }
            };
            const handleWindowHide = () => {
                if (seconds > 0) {
                    let miniProgramPhone = route.query.state
                        ? route.query.state
                        : route.query.miniProgramPhone;
                    let param = {
                        clueId: state.clueId,
                        browseSecond: seconds,
                        shareId: state.videoItem.vdId,
                        clueSource: 2,
                        beSharePhone: miniProgramPhone,
                        openId: state.openId,
                        title: state.videoItem.name,
                        createId: state.videoItem.loginUserId,
                        clueShareSource: route.params.clueShareSource,
                        recommend_Clue_Id:recommendClueId,
                        merchantId
                    };
                    let blob = new Blob([JSON.stringify(param)], {
                        type: "application/json",
                    });
                    navigator.sendBeacon(
                        `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                        blob
                    );
                    seconds = 0;
                    clearInterval(internal);
                }
            };
            const handleFirstAddClue = async () => {
                let miniProgramPhone = route.query.state
                    ? route.query.state
                    : route.query.miniProgramPhone;
                let param = {
                    shareId: state.videoItem.vdId,
                    clueSource: 2,
                    browseSecond: 0,
                    beSharePhone: miniProgramPhone,
                    openId: state.openId,
                    title: state.videoItem.name,
                    createId: state.videoItem.loginUserId,
                    clueShareSource: route.params.clueShareSource,
                    recommend_Clue_Id:recommendClueId,
                    merchantId
                };
                let data = await addClue(param);
                state.clueId = data;
                return data;
                // $.ajax({
                //     async: false,
                //     url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                //     type: "post",
                //     contentType: "application/json;charset=UTF-8",
                //     data: JSON.stringify(param),
                //     success: (data) => {
                //         if (data) {
                //             state.clueId = data;
                //             return data;
                //         }
                //     },
                // });
            };
            //封面加载完成处理事件
            const videoCoverImgLoad = () => {
                Toast.clear();
            };

            const handleVisibilityStateChange = () => {
                if (document.visibilityState === "hidden") {
                    handleAddClue();
                    clearInterval(internal);
                    seconds = 0;
                    //组件卸载时假设视频正在播放,需要停止视频
                    state.playOrPause = true;
                    playOrPauseVideo();
                }
            };
            onMounted(() => {
                internal = setInterval(() => {
                    seconds++;
                }, 1000);
                document.addEventListener(
                    "visibilitychange",
                    handleVisibilityStateChange
                );
                window.addEventListener("pagehide", handleWindowHide, false);
            });
            onUnmounted(() => {
                clearInterval(internal);
                handleAddClue();
                document.removeEventListener(
                    "visibilitychange",
                    handleVisibilityStateChange
                );
                window.removeEventListener("pagehide", handleWindowHide, false);
                //组件卸载时假设视频正在播放,需要停止视频
                state.playOrPause = true;
                playOrPauseVideo();
            });
            const countUserAction = async ({clueId, openId, type}) => {
                /**
                 * type:
                 *  1查看
                 *  2拨打
                 *  3保存
                 *  4转发
                 *  5提交咨询
                 *  6点赞
                 *  7收藏
                 *  8留言
                 */

                if (clueId) {
                    await addClueBehaviorInfo({clueId, openId, type});
                }
            };
            //初始化显示加载中
            Toast.loading({
                message: "加载中...",
                // forbidClick: true,
                duration: 20000,
                loadingType: "spinner",
            });

            //点赞数显示文本
          const totalLikesText = computed({
            get() {
              if (state.videoList[state.current].totalLikes < 10000) {
                return state.videoList[state.current].totalLikes;
              } else {
                return (
                    Math.round(state.videoList[state.current].totalLikes / 1000) / 10 +
                    "w"
                );
              }
            },
            set() {
            },
          });
          //转发数显示文本
          const forwardingNumberText = computed({
            get() {
              if (state.videoList[state.current].forwardingNumber < 10000) {
                return state.videoList[state.current].forwardingNumber;
              } else {
                return (
                    Math.round(state.videoList[state.current].forwardingNumber / 1000) / 10 +
                    "w"
                );
              }
            },
            set() {
            },
          });
          //画图
          const savePoster = () =>{
            console.log("生成海报================")
            posterState.showOverlay = true;
            // html2canvasChange()
            nextTick(() => {
              // var canvas2 = document.createElement("canvas");
              let _canvas = imageWrapper.value;
              const box = window.getComputedStyle(_canvas);
              // DOM 节点计算后宽高
              const width = parseInt(box.width, 10);
              const height = parseInt(box.height, 10);
              // 获取像素比
              const scaleBy = DPR();
              // 创建自定义 canvas 元素
              const canvas = document.createElement("canvas");
              // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
              canvas.width = width * scaleBy;
              canvas.height = height * scaleBy;
              // 设定 canvas css宽高为 DOM 节点宽高
              canvas.style.width = `${width}px`;
              canvas.style.height = `${height}px`;
              html2canvas(_canvas, {
                useCORS: true,
                background: "#ffffff",
                height: document.getElementById('saveposter').scrollHeight,
                windowHeight: document.getElementById('saveposter').scrollHeight,
                canvas,
              }).then((canvas) => {
                var context = canvas.getContext("2d");
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;
                context.imageSmoothingEnabled = false;
                let dataURL = canvas.toDataURL("image/png");
                posterState.showImg = false
                nextTick(() => {
                  cardImgRef.value.style.width = width + "px";
                  cardImgRef.value.style.height = height + "px";
                  posterState.imgSrc = dataURL;
                  posterState.showImg = false
                });
              });
            });
          }
          const DPR = () => {
            if (window.devicePixelRatio && window.devicePixelRatio > 1) {
              return window.devicePixelRatio;
            }
            return 1;
          };

            return {
                imageWrapper,
                cardImgRef,
                savePoster,
                DPR,
                ...toRefs(posterState),
                ...toRefs(state),
                onPlayerEnded,
                changeProcess,
                playOrPauseVideo,
                playvideo,
                ontimeupdate,
                onChange,
                onTouchmove,
                onProgressClick,
                changeFabulous,
                // checkSubscribe,
                comback,
                editVideo,
                loadShow,
                onSelectShare,
                navigateToUserCard,
                videoCoverImgLoad,
                totalLikesText,
                handleTabsChange,
                loadCuleSDetail,
                forwardingNumberText,
                downloadVideo
            };
        },
    };
</script>
<!--scoped让样式只作用于当前组件,不互相污染-->
<!--<style scoped>-->
<style scoped lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.wrapperPoster{
  //margin-top: 100px;
  height: 350px;
  overflow-x: hidden;
  //position: absolute;
  position: fixed;
  top: 60px;
  //bottom: 0;
  left: 0;
  right: 0;
  .poster-details{
    margin: 0 auto;
    width: 250px;
    overflow-x: hidden;
    >img{
      width: 250px;
      display: block;
    }
    .text-qrcode{
      background-color: #ffff;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      flex: 1;
      .user-info-tag{
        .user-info{
          display: flex;
          align-items: center;
          img{
            border-radius: 20px;
            width: 40px;
            height: 40px;
            object-fit: cover;
            flex-shrink: 0;
          }
          .left-text{
            text-align: left;
            //flex: 1;
            margin-left: 4px;
            p {
              margin: 0;
              padding: 0;
            }
            p:nth-child(1){
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
              color: #333333;
            }
            p:nth-child(2){
              font-size: 10px;
              color: #333333;
              //line-height: 20px;
            }
          }
        }
        .user-tag{
          display: flex;
          flex-wrap: wrap;
          .user-tag-item{
            margin-top: 5px;
            margin-right: 5px;
            //padding-left: 5px;
            padding:2px 5px;
            color: #289FFF;
            background-color: #E6ECFF;
            font-size: 9px;
            border-radius: 4px;
          }
        }
      }


      .qrCodeUrl-all{
        //position: relative;
        //text-align: center;
        //height: 60px;
        border-left: 1px solid #F1F3F6;
        padding-left: 18px;
        margin-left: 8px;
        flex-shrink:0;
        .qrCodeUrl-cls{
          //width: 60px;
          //height: 60px;
          background-color: #FFFFFF;
          border-radius: 30px;
          //position: relative;
          //top: -33px;
          //text-align: center;
          img{
            width: 60px;
            //border-radius: 25px;
            //position: relative;
            //margin-top: 50%;
            //transform: translateY(-50%);
          }
        }
        >p{
          //position: relative;
          text-align: center;
          font-size: 10px;
          font-weight: 400;
          color: #666666;
          margin: 0;
          //top: -40px;
        }
      }
    }
  }
  .posterImg-hb{
    height: 400px;
    overflow-x: hidden;
  }
}
    .related-clues {
        text-align: left;
        padding: 5px 16px;
        //background: #f7f7f7;
        .no-clues{
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 92vh;
            font-size: 24px;
            font-weight: 500;
        }
        .van-cell {
            margin-bottom: 10px;
        }
        > p {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 600;
        }
        .clues-box {
            .img-name {
                height: 40px;
                padding: 5px 0;
                img {
                    display: block;
                    height: 40px;
                    width: 40px;
                    border-radius: 20px;
                    float: left;
                }
                .creater-info {
                    float: left;
                    margin-left: 10px;
                    font-size: 14px;
                    text-align: left;
                    p {
                        margin: 0;
                    }
                    > p:nth-child(1) {
                        font-weight: 600;
                    }
                    > p:nth-child(2) {
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.4);
                    }
                }
            }
            .clues-details {
                background-color: #f7f7f7;
                padding: 5px;
                > p {
                    margin: 0;
                    font-size: 12px;
                }
            }
        }

    }
</style>
<style>
    .clear {
        clear: both;
    }

    .video-player {
        height: 100vh;
        width: 100vw;
    }

    .product_swiper {
        width: 100vw;
        height: 100vh;
    }

    .van_swipe {
        width: 100vw;
        height: calc(100vh - 44px);
        margin: 0 auto;
        position: relative;
    }

    .van-swipe {
        width: 100%;
        height: 100%;
    }

    .container_box {
        width: 100vw;
        height: calc(100vh - 44px);;
        background: #000;
    }
    .shared-height{
        height: 100vh;
    }
    .video_container {
        z-index: 998;
        width: 100vw;
        height: calc(100vh - 44px);
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .video_box {
        object-fit: contain !important;
        z-index: 999;
        width: 100%;
        max-height: 100%;
        /*height: 100%;*/
        /*position: absolute;*/
        /*left: 0;*/
        /*top: 0;*/
        overflow: hidden;
    }

    .video_container .play {
        max-height: 100%;
        width: 100%;
        object-fit: contain;
        z-index: 999;
        position: absolute;
    }

    .video_container video {
        /*object-position: 0 0;*/
    }

    .icon_play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        height: 60px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
    }

    /*头像， 点赞，转发 */
    .tag_image {
        width: 40px;
        height: 40px;
        border: 1px solid #fff;
        box-sizing: border-box;
        border-radius: 50%;
        margin-bottom: 20px;
        position: relative;
        box-shadow: 0px 0px 10px #9d9d9d;
    }

    .tag_add {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        border-radius: 67px;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin: 0 auto;
        z-index: 1;
        font-size: 20px;
        color: #f44;
        background: #fff;
    }

    .tag_dui {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        border-radius: 67px;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 0 auto;
        z-index: 1;
        font-size: 11px;
        color: #f44;
        background: #fff;
    }

    .tag_dui_active {
        opacity: 0;
        animation: showFollow 1.4s ease-in-out 0s;
    }

    @keyframes showFollow {
        0% {
            opacity: 1;
            color: #fff;
            background: #f44;
            transform: rotate(-180deg) scale(1);
        }
        35% {
            opacity: 1;
            color: #f44;
            background: #fff;
            transform: rotate(0deg) scale(1.2);
        }
        80% {
            opacity: 1;
            color: #f44;
            transform: scale(1.2);
        }
        100% {
            opacity: 0;
            display: none;
            color: #f44;
            transform: scale(0);
        }
    }

    .tools_right {
        z-index: 1001;
        position: absolute;
        right: 16px;
        bottom: 180px;
    }

    .tools_r_li {
        margin-bottom: 20px;
        color: #fff;
        font-size: 14px;
        position: relative;
    }

    .tools_r_li:last-child {
        margin-bottom: 0px;
    }

    .icon_right {
        margin-bottom: 5px;
        font-size: 42px;
        display: block;
        text-shadow: 0px 0px 10px #9d9d9d;
        /*transition: .5s;*/
    }

    .production_box {
        pointer-events: none;
        z-index: 1001;
        position: absolute;
        /* right: 16px; */
        bottom: 0px;
        text-align: left;
        padding: 0 15px 15px 15px;
        color: #fff;
        width: 100%;
        left: 0;
        box-sizing: border-box;
        background: -webkit-linear-gradient(
                top,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
        );
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
        /* 标准的语法 */
    }

    .production_name {
        pointer-events: none;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .production_des {
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-height: 62px;
        font-size: 13px;
    }

    .fabulous_active {
        color: #f44;
        animation: showHeart 0.5s ease-in-out 0s;
    }

    @keyframes showHeart {
        0% {
            color: #f44;
            transform: scale(1);
        }

        25% {
            color: #fff;
            transform: scale(0);
        }

        80% {
            color: #f44;
            transform: scale(1.2);
        }

        100% {
            color: #f44;
            transform: scale(1);
        }
    }

    /*评论样式*/
    ::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    :-moz-placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    ::-moz-placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    :-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    .shoucang1_num {
        text-align: center;
        width: 30px;
        font-size: 12px;
        /* right: -4px; */
        position: relative;
    }

    .v-enter,
    .v-leave-to {
        opacity: 0;
        transform: translateY(80px);
    }

    .v-enter-active,
    .v-leave-active {
        transition: all 0.5s ease;
    }

    /*添加进场效果*/
    .v-move {
        transition: all 1s ease;
    }

    .v-leave-active {
        position: absolute;
    }

    /*评论样式*/

    /*回退样式*/
    .nav-bar-left-not-shared {
        position: fixed;
        z-index: 3;
        left: 16px;
        top: 5px;
        /*iphoneX头部安全区域*/
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }
    .nav-bar-left {
        position: fixed;
        z-index: 3;
        left: 16px;
        top: 45px;
        /*iphoneX头部安全区域*/
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }
    /*右上角编辑按钮*/
    .nav-bar-right {
        position: fixed;
        z-index: 3;
        right: 16px;
        top: 45px;
        /*iphoneX头部安全区域*/
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }
    .nav-bar-right-not-shared{
        position: fixed;
        z-index: 3;
        left: 340px;
        top: 5px;
        /*iphoneX头部安全区域*/
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }
    .video_detail_share_box .van-share-sheet__cancel::before {
        height: 0;
    }

    .video_detail_share_box .van-share-sheet__cancel {
        height: 48px;
        width: 343px;
        background-color: #f7f7f7;
        color: #333333;
        border-radius: 5px;
        /*//    position: relative;*/
        /*//    transform: translateX(50%);*/
        margin-left: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .video_detail_share_box .van-share-sheet__options {
        color: #333333;
        display: flex;
        padding: 0 20px;
        justify-content: space-around;
    }
    .video_detail_share_box .van-popup--bottom{
        width: 50%;
    }
    .progressBar {
        position: absolute;
        width: 100%;
        height:3px;
        background-color: #887b7b;
        bottom: 10px;
        z-index: 1002;
        /*iphoneX底部安全区域*/
        margin-bottom: constant(safe-area-inset-bottom);
        margin-bottom: env(safe-area-inset-bottom);
    }
    .timeBar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #fff;
    }
    .progressIcon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: -13.5px;
        text-align: center;
        /*background-color: rgba(0,0,0,1);*/
    }
    .progressIconEntity {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

</style>
