import { reactive } from 'vue'
import {getVideoDetailByPage} from "../api/video";

export function useVideosPlay() {
    // let videoProcessInterval;
    const state = reactive({
        activeTab:0,
        belongMerchantId:0,
        hideTitleBar: false,
        loading: true,
        page: 1,
        shareTag : false,
        cardUrl: "",
        headImageUrl: "http://npjy.oss-cn-beijing.aliyuncs.com/images/file-1575449277018pF3XL.jpg",
        originalSharerName: "测试分享人",
        current: 0,
        initialSwipe:0,
        videoList: [
        ],
        withClueList:[],
        videoItem:{
        },
        openId:null,
        isVideoCoverShow: true,
        playOrPause: false,
        iconPlayShow: true,
        // videoProcess: 0,
        vdId: 0,
        fileHost: process.env.VUE_APP_WATCH_FILE_API + "/",
        showShare: false,
        showDetails:true,
        options:[
            { name: '微信', icon: require('../assets/icons/weChat2.png' )},
            { name: "朋友圈", icon: require("../assets/icons/friends2.png") },
            { name: "复制链接", icon: require("@/assets/icons/copy-link.png") }
        ],
        showVideoProgress: false,
        clueId:'',
        from: ""
    })

    // const changeProcess = () => {
    //     let video = document.querySelectorAll("video")[state.current];
    //     let currentTime = video.currentTime.toFixed(1);
    //     let duration = video.duration.toFixed(1);
    //     state.videoProcess = parseInt((currentTime / duration).toFixed(2) * 100);
    // };
    //滑动改变播放的视频
    const onChange = (index) => {
        //改变的时候 暂停当前播放的视频
        // clearInterval(videoProcessInterval);
        // state.videoProcess = 0;
        let video = document.querySelectorAll("video")[state.current];
        video.pause();
        state.playOrPause = false;
        state.current = index;
        //切换直接自动播放下一个
        state.isVideoCoverShow = false;
        if (index >= state.videoList.length -2) {
            //获取下一条视频
            state.page = state.page + 1
            getVideoDetailByPage({current: state.page, belongMerchantId: state.belongMerchantId, openId: state.openId}).then(data => {
                let records = data.records
                for (let recordIndex in records) {
                    if(records[recordIndex].vdId == state.vdId) {
                        records.splice(recordIndex,1)
                    }
                }
                if(records.length > 0) {
                    state.initialSwipe = index
                    state.videoList.push(...records)
                }
            })
            // state.videoList.push(...newVideoList)
        }
        //切换时隐藏进度条
        state.showVideoProgress = false
        playOrPauseVideo();
    };
    // 开始播放
    const playvideo = () => {
        let video = document.querySelectorAll("video")[state.current];
        state.isVideoCoverShow = false;
        state.iconPlayShow = false;
        video.play();
        state.playOrPause = true
        window.onresize = function () {
            video.style.width = window.innerWidth + "px";
            video.style.height = window.innerHeight + "px";
        };
        state.showVideoProgress = true
        // videoProcessInterval = setInterval(() => {
        //     changeProcess(video);
        // }, 100);
    };
    const playOrPauseVideo = () => {
        //暂停\播放
        let video = document.querySelectorAll("video")[state.current];
        if (state.playOrPause) {
            video.pause();
            state.iconPlayShow = true;
            // clearInterval(videoProcessInterval);
        } else {
            video.play();
            state.iconPlayShow = video.paused? true: false
            //判断是否显示进度条
            state.showVideoProgress = !video.paused
            // videoProcessInterval = setInterval(() => {
            //     changeProcess(video);
            // }, 100);
        }
        state.playOrPause = !state.playOrPause;
    };

    //video的timeupdate事件,video播放进度
    const ontimeupdate = () => {
        let video = document.querySelectorAll("video")[state.current];
        let timeBar = document.querySelectorAll(".progressBar .timeBar")[state.current];
        let progressIcon =  document.querySelectorAll(".progressBar .progressIcon")[state.current];
        let progressBar = document.querySelectorAll(".progressBar")[state.current];
        var currentPos = video.currentTime; //Get currenttime
        var maxduration = video.duration; //Get video duration
        var percentage = currentPos / maxduration; //in %
        let progressBarOffsetWidth = progressBar.offsetWidth
        let progressIconOffsetWidth = progressIcon.offsetWidth
        timeBar.style.width = percentage * progressBarOffsetWidth+ "px";
        progressIcon.style.left = (percentage * progressBarOffsetWidth - progressIconOffsetWidth/2)  + "px";
    }

    //进度条滑块拖动事件
    const onTouchmove = (ev) => {
        event.preventDefault()
        var touch = ev.targetTouches[0];
        let progressBar = document.querySelectorAll(".progressBar")[state.current];
        let timeBar = document.querySelectorAll(".progressBar .timeBar")[state.current];
        let progressIcon =  document.querySelectorAll(".progressBar .progressIcon")[state.current];
        let video = document.querySelectorAll("video")[state.current];
        let durationWidth = touch.clientX
        let progressBarOffsetWidth = progressBar.offsetWidth
        let progressIconOffsetWidth = progressIcon.offsetWidth
        let maxDuration = video.duration
        timeBar.style.width = durationWidth + "px"
        progressIcon.style.left = (durationWidth - progressIconOffsetWidth/2)+ "px"
        video.currentTime = durationWidth/progressBarOffsetWidth * maxDuration
    }

    //进度条点击事件
    const onProgressClick = (ev) => {
        event.preventDefault()
        let timeBar = document.querySelectorAll(".progressBar .timeBar")[state.current];
        let progressIcon =  document.querySelectorAll(".progressBar .progressIcon")[state.current];
        let video = document.querySelectorAll("video")[state.current];
        let progressBar = document.querySelectorAll(".progressBar")[state.current];
        let progressBarOffsetWidth = progressBar.offsetWidth
        let progressIconOffsetWidth = progressIcon.offsetWidth
        let durationWidth = ev.clientX
        let maxDuration = video.duration
        timeBar.style.width = durationWidth + "px"
        progressIcon.style.left =  (durationWidth - progressIconOffsetWidth/2)+ "px"
        video.currentTime = durationWidth/progressBarOffsetWidth * maxDuration
    }

    return {
        state,
        playOrPauseVideo,
        playvideo,
        onChange,
        ontimeupdate,
        onTouchmove,
        onProgressClick
        // changeProcess
    }
}
